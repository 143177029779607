import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data, location, pageContext }) => (
    <Layout>
        <SEO
            pagetitle={pageContext.tagtitle}
            pagedesc={pageContext.tagtitle}
            pagepath={location.pathname}
        />
        <main id="category">
                <div className="inner">
                    <h1>{pageContext.tagtitle}</h1>
                </div>

            <div id="articleList">
            {data.allContentfulBlogPost.edges.map(({ node }) => (
                <div className="articleListItem" key={node.id}>
                    <Link to={`/${node.slug}/`}>
                        <div className="articleView">
                            <figure>
                                <Img
                                    fluid={node.eyecatch.fluid}
                                    alt={node.eyecatch.description}
                                />
                            </figure>
                            <div className="viewDetail">
                                {node.category.map(cat => (
                                    <div id="viewCat" className={cat.categorySlug} key={cat.id}><p>{cat.category}</p></div>
                                ))}
                                <h2 className="viewTitle">{node.title}</h2>
                                <time datatime={node.publishDate}>
                                    {node.publishDateJP}
                                </time>
                            </div>
                        </div>
                    </Link>
                </div>
                ))}
            </div>
            <div className="breadCrumb">
                <ol>
                    <li>
                    <Link to={`/`}>TOP</Link>
                    </li>
                    <li>
                    {pageContext.tagtitle}
                    {/* <Link to={`/${pageContext.tagslug}/`}>{pageContext.tagtitle}</Link> */}
                    </li>
                </ol>
            </div>
        </main>
    </Layout>
)

export const query = graphql`
query($tagid: String!) {
    allContentfulBlogPost(
        sort: {fields: createdAt, order: DESC}
        filter: { tags: { elemMatch: { id: { eq: $tagid } } } }
        ) {
        edges {
          node {
            id
            title
            slug
            publishDate
            publishDateJP: publishDate(formatString: "YYYY/MM/DD")
            category {
              category
              categorySlug
              id
            }
            tags {
                id
                tagTitle
                tagSlug
            }
            eyecatch {
              fluid(maxWidth: 500) {
                ...GatsbyContentfulFluid_withWebp
              }
              description
            }
          }
        }
      }
}
`